import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import { Logo } from "../../assets/svg";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "utils/hooks/useAuth";
import { setUser } from "store/auth/userSlice";
import { Button, Spinner } from "components/ui";

import { MdClose, MdDelete } from "react-icons/md";
import { BiLogOut } from "react-icons/bi";
import { FaArrowLeft } from "react-icons/fa6";
import { GiCheckMark } from "react-icons/gi";
import { FiPlus } from "react-icons/fi";
import { RxHamburgerMenu } from "react-icons/rx";
import axiosInstance from "apiServices/axiosInstance";
import openNotification from "views/common/notification";

const MobileNavLink = ({ isActive, onClick, children }) => {
  // console.log("🚀 ~ MobileNavLink ~ isActive:", isActive)
  return (
    <button
      className={`text-gray-600 w-full relative overflow-hidden ${
        isActive ? "bg-[#DFEEFF]" : ""
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

// Define navigation links
const navigationDaycareMobileLinks = [
  { id: "/", label: "Enrolled Children" },
  { id: "/applications", label: "Applications" },
  // { id: "/waitlist", label: "Waitlist" },
  { id: "/view-daycare-teachers", label: "Staff" },
  { id: "/view-daycare-profile", label: "View Profile" },
  { id: "/edit-daycare-profile", label: "Edit Profile" },
];
const navigationParentMobileLinks = [
  { id: "/", label: "Home" },
  { id: "/browse-daycares", label: "Browse Daycares" },
  { id: "/profiles", label: "Profiles" },
  { id: "/my-favourites", label: "My Favourites" },
];
const navigationMobileLinks = [
  { id: "/browse-daycares", label: "Browse Daycares" },
];

const Header = (props) => {
  const navigate = useNavigate();
  const { signOut, setUserData } = useAuth();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { userData } = useSelector((state) => state?.auth?.user);
  // console.log("🚀 ~ userData123:", userData);
  const [isInCompleteFormModelVisible, setIsInCompleteFormModelVisible] =
    useState(false);
  const dropdownContentRef = useRef(null);
  const [isTogglerOpen, setIsTogglerOpen] = useState(false);
  // const [isSwitchProfileOpen, setSwitchProfileOpen] = useState(false);
  const [selectedProfile, setSelectedprofile] = useState({
    value: "",
    label: "",
  });
  // console.log("selectedProfile", selectedProfile);
  const [userRole, setUserRole] = useState("user");
  const [accounts, setAccounts] = useState([]);
  // console.log("🚀 ~ accounts:", accounts)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  //stripe related state
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [isStripeAddModalVisible, setIsStripeAddModelVisible] = useState(false);
  const [stripeAddDetailsUrl, setStripeAddDetailsUrl] = useState(false);
  const [isStripeUpdateModalVisible, setIsStripeUpdateModelVisible] =
    useState(false);
  const [stripeUpdateDetailsUrl, setStripeUpdateDetailsUrl] = useState(false);

  const dropdownRef = useRef(null);

  //useEffect for user management
  useEffect(() => {
    // console.log("inside effect")
    // set role
    if (userData && userData?.authority[0]) {
      setUserRole(userData.authority[0]);
    } else {
      //add by me
      setUserRole("user");
    }

    // set accounts and profile
    if (userData?.profile_data?.length && userData.selected_profile) {
      // console.log("enter into if condition");
      setSelectedprofile(userData.selected_profile);
      setAccounts(userData.profile_data);
    } else {
      //add by me
      // console.log("enter into else condition");
      setAccounts([{ value: "user", label: "user" }]);
      setSelectedprofile({
        value: "user",
        label: "user",
      });
    }
  }, [userData]);

  //InCompleted profile handler
  const handleCompleteProfile = () => {
    // console.log("click profiles");
    navigate("/daycare-profile");
    // navigate("/profiles");
  };

  // Web side handler

  //Auth handler
  const handleLogout = (e) => {
    // console.log("click logout");
    e.stopPropagation();
    signOut();
    navigate("/");
    setIsTogglerOpen(false);
  };
  const handleLogin = () => {
    // console.log("click logout");
    signOut();
    navigate("/auth/sign-in");
    setIsTogglerOpen(false);
  };
  const handleSignUp = () => {
    // console.log("click sign-up");
    navigate("/auth/sign-up");
    setIsTogglerOpen(false);
  };

  //handle toggler
  const handleToggler = () => {
    if (userData && userData?.authority[0] == "daycare") {
      if (userData?.profile_id == "") {
        setIsInCompleteFormModelVisible(true);
        // setIsTogglerOpen(!isTogglerOpen);
      } else {
        setIsTogglerOpen(!isTogglerOpen);
      }
    } else {
      setIsTogglerOpen(!isTogglerOpen);
    }
  };

  //useEffect for toggler management
  useEffect(() => {
    // Function to handle clicks outside the dropdown
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current?.contains(event.target) &&
        !dropdownContentRef.current?.contains(event.target)
      ) {
        setIsTogglerOpen(false);
      }
    };
    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //toggler list handler
  const handleDaycareTeachers = () => {
    navigate("/view-daycare-teachers");
    setIsTogglerOpen(false);
  };
  const handleViewDaycareProfile = () => {
    navigate("/view-daycare-profile");
    setIsTogglerOpen(false);
  };
  const handleEditDaycareProfile = () => {
    navigate("/edit-daycare-profile");
    setIsTogglerOpen(false);
  };
  const handleSwitchProfile = async (data) => {
    // console.log("🚀 ~ handleSwitchProfile ~ data:", data);
    // Web
    setSelectedprofile(data);
    await dispatch(
      await setUser({
        ...userData,
        profile_id: data?.profile_id,
        selected_profile: data,
      })
    );
    setIsTogglerOpen(false);
  };
  const handleAddDaycareProfile = () => {
    navigate("/daycare-profile");
    setIsTogglerOpen(false);
  };
  const handleDeleteDaycareProfile = (e) => {
    e.stopPropagation();
    setIsDeleteModalVisible(true);
    // toggleSwitchProfile();
  };
  const handleConfirm = async (e, profile_id) => {
    // console.log("🚀 ~ handleConfirm ~ profile_id:", profile_id)
    e.stopPropagation(); // Prevent bubbling
    try {
      setDeleteIsLoading(true);
      const response = await axiosInstance.delete(
        `user/daycare-profile/delete-daycare-details/${profile_id}/${userData.user_id}`
      );
      // console.log("🚀 ~ handleConfirm ~ response:", response)
      if (response.status) {
        setDeleteIsLoading(false);
        signOut();
        navigate("/");
        setIsDeleteModalVisible(false);
      } else {
        setIsDeleteModalVisible(true);
        setDeleteIsLoading(false);
      }
    } catch (error) {
      // console.log("getOneDaycareDetailsData error:", error.message);
      setDeleteIsLoading(false);
    }
  };
  const handleCancel = (e) => {
    // console.log("handleCancel")
    e.stopPropagation(); // Prevent bubbling
    setIsDeleteModalVisible(false);
    setIsInCompleteFormModelVisible(false);
    setIsStripeAddModelVisible(false);
    setIsStripeUpdateModelVisible(false);
  };

  //handler for addUpdateBankDetailsInStripe

  const handleBankDetailsInStripe = async (isUpdate = false) => {
    try {
      setIsStripeLoading(true); // This will trigger the useEffect if needed.

      // If updating, get existing bank details before proceeding
      let existingBankDetails = null;
      if (isUpdate) {
        const response = await axiosInstance.get(
          `user/get-daycare-bank-account/${userData?.selected_profile?.profile_id}`
        );
        if (response.status) {
          existingBankDetails = response.data || null;
          setIsTogglerOpen(false);
        } else {
          throw new Error("Failed to get existing bank details");
        }
      }

      // Build the payload
      const payload = {
        profile_id: userData?.selected_profile?.profile_id,
        email: userData?.user_email,
      };

      // Include additional details for update
      if (isUpdate && existingBankDetails?.daycare_bank_account_id) {
        payload.daycare_bank_account_id =
          existingBankDetails.daycare_bank_account_id;
        payload.stripe_account_id = existingBankDetails.stripe_account_id;
      }

      // Call the API to add or update bank details
      const response = await axiosInstance.post(
        `user/daycare-bank-account`,
        payload
      );

      if (response.status) {
        if (isUpdate) {
          setStripeUpdateDetailsUrl(response?.url || "");
          setIsStripeUpdateModelVisible(true);
        } else {
          setStripeAddDetailsUrl(response?.accountLink?.url || "");
          setIsStripeAddModelVisible(true);
        }
      } else {
        // Handle API response failure
        setIsStripeAddModelVisible(false);
        setIsStripeUpdateModelVisible(false);
      }
    } catch (error) {
      // console.error("Error in handleBankDetailsInStripe:", error);
      setIsStripeAddModelVisible(false);
      setIsStripeUpdateModelVisible(false);
    } finally {
      setIsStripeLoading(false);
    }
  };

  useEffect(() => {
    if (pathname === "/") {
      setUserData(userData?.user_id, pathname);
    }
  }, [pathname]);

  //handler for mobile
  const handleMobileLinkClick = (link) => {
    // console.log("🚀 ~ handleMobileLinkClick ~ link:", link)
    navigate(link);
    setIsTogglerOpen(false); // Close the mobile menu when a link is clicked
  };
  // const toggleSwitchProfile = async () => {
  //   setSwitchProfileOpen(!isSwitchProfileOpen);
  // };
  // const handleAccountSelect = async (account) => {
  //   //mobile
  //   setSelectedprofile(account);
  //   await dispatch(
  //     await setUser({
  //       ...userData,
  //       selected_profile: account,
  //     })
  //   );
  //   setSwitchProfileOpen(false);
  // };

  // function for role wise header
  const setRoleWiseHeader = (role) => {
    try {
      switch (role) {
        case "daycare":
          return (
            <>
              <div className="relative">
                <div className="flex justify-between item-center font-avenir py-3  lg:py-5">
                  {/* Left-aligned section*/}
                  <div className="flex items-center gap-x-4 ">
                    <Link to="/">
                      <Logo />
                    </Link>
                    <div className="hidden lg:flex lg:gap-x-4">
                      {/* Navigation buttons */}

                      <NavLink
                        className={({ isActive }) =>
                          `text-[#333333]  hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${
                            isActive ? "bg-[#504CBC] text-white" : ""
                          }`
                        }
                        to="/"
                      >
                        Enrolled Children
                      </NavLink>
                      <NavLink
                        className={({ isActive }) =>
                          `text-[#333333] hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${
                            isActive ? "bg-[#504CBC] text-white" : ""
                          }`
                        }
                        to="/applications"
                      >
                        Applications
                      </NavLink>
                      {/* <NavLink
                      className={({ isActive }) => `text-[#333333] hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${isActive ? "bg-[#504CBC] text-white" : ""}`}
                      to="/waitlist"
                    >
                      Waitlist
                    </NavLink> */}
                    </div>
                  </div>

                  {/* Right-aligned section */}
                  <div className="flex items-center">
                    {/* mobile menu  */}
                    <div className="lg:hidden">
                      <RxHamburgerMenu
                        color="#504CBC"
                        onClick={handleToggler}
                        size={25}
                      />
                      {isInCompleteFormModelVisible && (
                        <div className="fixed top-0 left-0 z-10 w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center">
                          <div className="container">
                            <div className="w-full mx-auto bg-white rounded-2xl overflow-hidden shadow-md  relative sm:w-3/4  lg:w-1/2 2xl:w-1/3">
                              <MdClose
                                className="absolute top-2 right-3  cursor-pointer"
                                color="#ffffff"
                                size={25}
                                onClick={(e) => handleCancel(e)}
                              />
                              <div className="bg-[#504CBC] text-white text-center font-bold font-avenirbold py-[10px]">
                                <span className="text-[24px] ">Note</span>
                              </div>

                              <div className="text-center px-4 py-2 font-avenir lg:text-2xl">
                                <span>
                                  You must completed your daycare profile with
                                  details.
                                </span>
                              </div>

                              <div className="flex flex-col items-center gap-2 py-4 ">
                                <Button
                                  variant="solid"
                                  className="w-[240px]  border-[1px] rounded-xl text-base font-bold align-middle text-[#504CBC] "
                                  onClick={() => handleCompleteProfile()}
                                >
                                  Complete Profile
                                </Button>
                                <Button
                                  className="w-[240px]   align-middle"
                                  color="#FF6131"
                                  onClick={(e) => handleLogout(e)}
                                >
                                  Logout
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {isTogglerOpen && (
                        <div
                          ref={dropdownRef}
                          className="fixed top-0 right-0 sm:w-1/2 w-full h-full bg-white z-40 shadow-lg overflow-scroll"
                        >
                          <nav className="flex flex-col items-start ">
                            <div className="w-full border-b-[2px] border-[#504CBC] flex items-center  text-center pt-1 pb-2">
                              <div className="w-[10%]">
                                <FaArrowLeft
                                  className="ml-[16px] text-[#504CBC]"
                                  size={25}
                                  onClick={() => {
                                    setIsTogglerOpen(false);
                                  }}
                                />
                              </div>
                              <div className="font-avenir text-center font-bold text-[24px] text-[#504CBC] w-[90%]">
                                Menu
                              </div>
                            </div>

                            <div className="w-full px-5 pt-6 pb-2.5 bg-gray-100">
                              <div className="sm:w-full  sm:pt-[0px] pt-[10px]">
                                <div className="font-outfit  text-base text-[#333333] font-bold capitalize">
                                  <span>
                                    {" "}
                                    Hello,{" "}
                                    {userData?.selected_profile?.daycare_name
                                      ? `${userData.selected_profile.daycare_name}`
                                      : `Daycare`}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="sm:pt-0 pt-3.5 underline underline-offset-4 decoration-[#504CBC] text-[#000000]">
                            <span className="font-semibold text-sm">
                              Edit Profile
                            </span>
                          </div> */}
                            </div>

                            {navigationDaycareMobileLinks?.map((link) => {
                              // console.log("🚀 ~ {navigationDaycareMobileLinks?.map ~ link:", link)
                              return (
                                <>
                                  <MobileNavLink
                                    key={link.id}
                                    isActive={pathname === link.id}
                                    onClick={() => {
                                      // console.log(" ")
                                      handleMobileLinkClick(link.id);
                                      setIsTogglerOpen(false);
                                    }}
                                  >
                                    <span className="text-[#333333] font-medium font-avenir hover:bg-[#DFEEFF] w-full py-[10px] pl-[16px] flex text-[18px]">
                                      {link.label}
                                    </span>
                                  </MobileNavLink>
                                </>
                              );
                            })}

                            {userData?.selected_profile
                              ?.submit_bank_account_details && (
                              <>
                                <div className="px-4 py-[10px]">
                                  <button
                                    onClick={() =>
                                      handleBankDetailsInStripe(true)
                                    } // Pass true for update
                                    className="text-[#333333] font-medium text-[18px] text-center"
                                  >
                                    Update bank details
                                  </button>
                                </div>
                              </>
                            )}

                            <div className="pt-2">
                              <span className="text-[12px] pl-[15px] font-bold text-black">
                                Switch Account
                              </span>
                            </div>
                            {accounts.length &&
                              accounts.map((info, index) => {
                                return (
                                  <>
                                    {info.daycare_name ===
                                    selectedProfile.daycare_name ? (
                                      <>
                                        <div
                                          className="flex justify-between items-center bg-gray-200
                                          px-4 py-[10px] text-[16px] text-[#FF6131] hover:bg-gray-100 w-full text-left capitalize"
                                        >
                                          <div>
                                            <button
                                              disabled={true}
                                              className={``}
                                            >
                                              {info.daycare_name}
                                            </button>
                                          </div>
                                          <div className="flex items-center">
                                            <span className=" text-[#504CBC] mr-4">
                                              <GiCheckMark />
                                            </span>
                                            <span className=" text-[#504CBC] mr-4">
                                              <MdDelete
                                                onClick={(e) =>
                                                  handleDeleteDaycareProfile(e)
                                                }
                                              />
                                              {isDeleteModalVisible && (
                                                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
                                                  <div className="w-1/4 mx-auto bg-white rounded-xl overflow-hidden shadow-md border-[1px] border-black">
                                                    <div className="bg-[#504CBC] text-white text-center font-bold font-avenirbold">
                                                      <span className="text-[24px]">
                                                        Delete
                                                      </span>
                                                    </div>
                                                    <div className="px-6 py-4">
                                                      <div className="text-center">
                                                        <span className="font-medium text-[24px] text-[#000000] text-center">
                                                          Confirm delete Daycare
                                                          profile
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="flex justify-center pt-[28px]">
                                                      <Button
                                                        variant="solid"
                                                        className=" h-[40px] w-[150px] text-xl border-[1px] font-bold rounded-xl mx-9 flex items-center justify-center"
                                                        color={"#00C1C1"}
                                                        loading={
                                                          deleteIsLoading
                                                        }
                                                        onClick={(e) =>
                                                          handleConfirm(
                                                            e,
                                                            info.profile_id
                                                          )
                                                        }
                                                      >
                                                        Confirm
                                                      </Button>
                                                    </div>
                                                    <div className="flex justify-center pt-[28px] pb-[30px]">
                                                      <Button
                                                        variant="solid"
                                                        className="bg-[#828282] text-[#ffffff] h-[40px] w-[150px] text-xl border-[1px] font-bold rounded-xl mx-9 flex items-center justify-center"
                                                        color={"#828282"}
                                                        onClick={(e) =>
                                                          handleCancel(e)
                                                        }
                                                      >
                                                        Cancel
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          onClick={(e) => {
                                            e.preventDefault();
                                            // console.log("dfdfdf");
                                            handleSwitchProfile(info);
                                          }}
                                          className={`flex justify-between items-center px-4 py-[10px] ] text-[16px] text-[#000000] hover:bg-gray-100 w-full text-left capitalize`}
                                        >
                                          <div>
                                            <button>{info.daycare_name}</button>
                                          </div>
                                          <div className="flex">
                                            <span className=" text-[#000000] mr-4"></span>
                                            <span className=" text-[#000000] mr-4">
                                              {/* <MdDelete
                                              onClick={(e) => handleDeleteDaycareProfile(e)}
                                            /> */}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                );
                              })}

                            <div>
                              <button
                                className="px-4 py-2 w-full rounded-md focus:outline-none  bg-[#fff]   text-[#757575] font-semibold text-base"
                                onClick={handleAddDaycareProfile}
                              >
                                <div className="flex items-center">
                                  <FiPlus />
                                  <span className="pl-[10px]">
                                    Add another account
                                  </span>
                                </div>
                              </button>
                            </div>

                            <div>
                              <Button
                                variant="solid"
                                icon={<BiLogOut />}
                                onClick={(e) => handleLogout(e)}
                                className="py-[10px] px-[30px] my-[40px] ml-[16px] rounded-md bg-[#504CBC] border  text-[#ffffff] font-medium text-[18px]"
                              >
                                Logout
                              </Button>
                            </div>
                          </nav>
                        </div>
                      )}

                      {/* {isTogglerOpen && (
                      <div
                        className="fixed top-0 left-0 w-full h-fit bg-black opacity-50 z-30"
                        onClick={handleToggler}
                      ></div>
                    )} */}
                    </div>

                    <div className="hidden lg:flex lg:justify-end  lg:gap-x-4  lg:items-center">
                      <div className="flex items-center gap-2">
                        <span className="text-[#333333] font-black text-base pr-2 capitalize line-clamp-1">
                          Hello,{" "}
                          {userData?.selected_profile?.daycare_name
                            ? `${userData.selected_profile.daycare_name}`
                            : `Daycare`}
                        </span>

                        <div className="cursor-pointer">
                          <RxHamburgerMenu
                            className="text-[#FF6131]"
                            onClick={handleToggler}
                            size={25}
                          />
                        </div>
                        {isInCompleteFormModelVisible && (
                          <div className="fixed top-0 left-0 z-10 w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center">
                            <div className="container">
                              <div className="w-full mx-auto bg-white rounded-2xl overflow-hidden shadow-md  relative sm:w-3/4  lg:w-1/2 2xl:w-1/3">
                                <MdClose
                                  className="absolute top-2 right-3  cursor-pointer"
                                  color="#ffffff"
                                  size={25}
                                  onClick={(e) => handleCancel(e)}
                                />
                                <div className="bg-[#504CBC] text-white text-center font-bold font-avenirbold py-[10px]">
                                  <span className="text-[24px] ">Note</span>
                                </div>

                                <div className="text-center px-4 py-2 font-avenir lg:text-2xl">
                                  <span>
                                    You must completed your daycare profile with
                                    details.
                                  </span>
                                </div>

                                <div className="flex flex-col items-center gap-2 py-4 ">
                                  <Button
                                    className=""
                                    onClick={() => handleCompleteProfile()}
                                  >
                                    Complete Profile
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {isTogglerOpen && (
                          <div
                            ref={dropdownContentRef}
                            className="dropdown-content transition-all absolute top-16 w-[300px] mt-2 origin-top-right bg-white border border-gray-200 divide-y  divide-[#BDBDBD] rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
                          >
                            <div className="px-4 py-2">
                              <button
                                onClick={handleDaycareTeachers}
                                className="text-black text-[16px] text-center"
                              >
                                Staff
                              </button>
                            </div>
                            <div className="px-4 py-2">
                              <button
                                onClick={handleViewDaycareProfile}
                                className="text-black text-[16px] text-center"
                              >
                                View profile
                              </button>
                            </div>
                            <div className="px-4 py-2">
                              <button
                                onClick={handleEditDaycareProfile}
                                className="text-black text-[16px] text-center"
                              >
                                Edit profile
                              </button>
                            </div>
                            {userData?.selected_profile
                              ?.submit_bank_account_details && (
                              <>
                                <div className="px-4 py-2">
                                  <button
                                    onClick={() =>
                                      handleBankDetailsInStripe(true)
                                    } // Pass true for update
                                    className="text-black text-[16px] text-center"
                                  >
                                    Update bank details
                                  </button>
                                </div>
                              </>
                            )}

                            <div className="pt-2">
                              <span className="text-[12px] pl-[15px] font-bold text-black">
                                Switch Account
                              </span>
                            </div>
                            {accounts.length &&
                              accounts.map((info, index) => {
                                // console.log("🚀 ~ accounts.map ~ info:", info)
                                return (
                                  <>
                                    {info.daycare_name ===
                                    selectedProfile.daycare_name ? (
                                      <>
                                        <div
                                          className="flex justify-between items-center bg-gray-200
                                      px-4 py-[10px] text-[16px] text-[#FF6131] hover:bg-gray-100 w-full text-left capitalize"
                                        >
                                          <div>
                                            <button
                                              disabled={true}
                                              className={``}
                                            >
                                              {info.daycare_name}
                                            </button>
                                          </div>
                                          <div className="flex items-center">
                                            <span className=" text-[#504CBC] mr-4">
                                              <GiCheckMark />
                                            </span>
                                            <span className=" text-[#504CBC] mr-4">
                                              <MdDelete
                                                onClick={(e) =>
                                                  handleDeleteDaycareProfile(e)
                                                }
                                              />
                                              {isDeleteModalVisible && (
                                                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                                                  <div className="container">
                                                    <div className="w-full mx-auto bg-white rounded-[20px] overflow-hidden shadow-md relative sm:w-3/4 lg:w-1/2 2xl:w-1/3 ">
                                                      <div className="bg-[#504CBC] text-white text-center font-bold font-avenirbold">
                                                        <span className="text-[24px]">
                                                          Delete
                                                        </span>
                                                      </div>
                                                      <div className="text-center px-4 py-2 font-avenir lg:text-2xl">
                                                        <span className="font-medium text-[24px] text-[#000000] text-center">
                                                          Confirm delete Daycare
                                                          profile
                                                        </span>
                                                      </div>
                                                      <div className="flex flex-col justify-center  items-center gap-4 px-4 py-6">
                                                        <Button
                                                          className=""
                                                          variant="solid"
                                                          color="#00C1C1"
                                                          loading={
                                                            deleteIsLoading
                                                          }
                                                          onClick={(e) =>
                                                            handleConfirm(
                                                              e,
                                                              info.profile_id
                                                            )
                                                          }
                                                        >
                                                          Confirm
                                                        </Button>
                                                        <Button
                                                          className="text-black"
                                                          color="#828282"
                                                          onClick={(e) =>
                                                            handleCancel(e)
                                                          }
                                                        >
                                                          Cancel
                                                        </Button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          onClick={(e) => {
                                            e.preventDefault();
                                            // console.log("dfdfdf");
                                            handleSwitchProfile(info);
                                          }}
                                          className={`flex justify-between items-center px-4 py-[10px] ] text-[16px] text-[#000000] hover:bg-gray-100 w-full text-left capitalize`}
                                        >
                                          <div>
                                            <button>{info.daycare_name}</button>
                                          </div>
                                          <div className="flex">
                                            <span className=" text-[#000000] mr-4"></span>
                                            <span className=" text-[#000000] mr-4">
                                              {/* <MdDelete
                                              onClick={(e) => handleDeleteDaycareProfile(e)}
                                            /> */}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            {/* 
                          <button className="block px-4 pb-[10px] pt-[10px] text-[16px] text-[#FF6131] hover:bg-gray-100 w-full text-left">
                            Hilltop Centre
                          </button> */}

                            <button
                              className="block px-4 pb-[10px] pt-[10px] text-[16px] text-[#757575] hover:bg-gray-100 w-full text-left"
                              onClick={handleAddDaycareProfile}
                            >
                              + Add another account
                            </button>
                            {/* Add more switch profile options as needed */}
                          </div>
                        )}

                        {/* Logout button */}
                        <button
                          onClick={(e) => handleLogout(e)}
                          className="text-black hover:bg-[#504CBC] text-[16px] font-medium hover:text-white rounded-full px-6 py-2 "
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isStripeLoading ? (
                <>
                  <div className="fixed top-0 left-0 w-full h-full  bg-black bg-opacity-50  flex items-center justify-center">
                    <div className="container">
                      <div className="h-[80vh]  flex justify-center items-center">
                        <Spinner size="3.25rem" />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                isStripeUpdateModalVisible && (
                  <div className="fixed top-0 left-0 w-full h-full  bg-black bg-opacity-50  flex items-center justify-center">
                    <div className="container">
                      <div className="w-full mx-auto bg-white rounded-[20px] overflow-hidden shadow-md relative sm:w-3/4 lg:w-1/2 2xl:w-1/3 ">
                        <div className="bg-[#504CBC] text-white text-center font-bold font-avenirbold">
                          <span className="text-[24px]">
                            Update bank details
                          </span>
                        </div>
                        <div className="text-center px-4 py-2 font-avenir lg:text-2xl">
                          <span className="font-medium text-[24px] text-center">
                            It looks like you need to update your bank details
                            on Stripe. Please update your information to ensure
                            uninterrupted autopay and to keep your daycare
                            active on our marketplace.
                          </span>
                        </div>
                        <div className="flex flex-col justify-center  items-center gap-4 px-4 py-6">
                          <Link to={`${stripeUpdateDetailsUrl}`}>
                            <Button
                              className=""
                              variant="solid"
                              color="#00C1C1"
                              // loading={isDeleteLoading}
                              // onClick={(event) =>
                              //   handleConfirmToAddUpdateBankDetailsInStripe(event)
                              // }
                            >
                              Update
                            </Button>
                          </Link>
                          <Button
                            className=""
                            color={"#828282"}
                            onClick={(event) => handleCancel(event)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </>
          );
        case "parent":
          return (
            <>
              <div className=" flex justify-between item-center font-avenir py-3  lg:py-5">
                {/* Left-aligned section*/}
                <div className="flex items-center gap-x-4 ">
                  <Link to="/">
                    <Logo />
                  </Link>
                  <div className=" hidden lg:flex lg:gap-x-4 ">
                    {/* Navigation buttons */}
                    <NavLink
                      className={({ isActive }) =>
                        ` text-[#333333] hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${
                          isActive ? "bg-[#504CBC] text-white" : ""
                        }`
                      }
                      to="/"
                    >
                      Home
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        ` text-[#333333] hover:bg-[#504CBC] text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${
                          isActive ? "bg-[#504CBC] text-white" : ""
                        }`
                      }
                      to="/browse-daycares"
                    >
                      Browse Daycares
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        ` text-[#333333] hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${
                          isActive ? "bg-[#504CBC] text-white" : ""
                        }`
                      }
                      to="/profiles"
                    >
                      Profiles
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        ` text-[#333333] hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${
                          isActive ? "bg-[#504CBC] text-white" : ""
                        }`
                      }
                      to="/my-favourites"
                    >
                      My Favourites
                    </NavLink>
                  </div>
                </div>

                {/* Right-aligned section */}
                <div className="flex items-center">
                  <div className="lg:hidden">
                    <RxHamburgerMenu
                      color="#504CBC"
                      onClick={handleToggler}
                      size={25}
                    />
                    {isTogglerOpen && (
                      <div
                        ref={dropdownRef}
                        className="fixed top-0 right-0  w-full h-full bg-white z-40 shadow-lg overflow-scroll sm:w-1/2 "
                      >
                        <nav className="flex flex-col items-start ">
                          <div className="w-full border-b-[2px] border-[#504CBC] flex items-center  text-center pt-1 pb-2">
                            <div className="w-[10%]">
                              <FaArrowLeft
                                className="ml-[16px] text-[#504CBC]"
                                size={25}
                                onClick={() => {
                                  setIsTogglerOpen(false);
                                }}
                              />
                            </div>
                            <div className="font-avenir text-center font-bold text-[24px] text-[#504CBC] w-[90%]">
                              Menu
                            </div>
                          </div>

                          <div className="w-full  px-4 pt-6 pb-2.5 bg-gray-100">
                            <div className="sm:w-full  sm:pt-[0px] pt-[10px]">
                              <div className="font-outfit  text-base text-[#333333] font-bold">
                                <span>
                                  {" "}
                                  Hello,{" "}
                                  {userData?.selected_profile
                                    ?.contact_first_name
                                    ? `${userData.selected_profile.contact_first_name}`
                                    : "Parent"}
                                </span>
                              </div>
                            </div>
                            {/* <div className="sm:pt-0 pt-3.5 underline underline-offset-4 decoration-[#504CBC] text-[#000000]">
                            <span className="font-semibold text-sm">
                              Edit Profile
                            </span>
                          </div> */}
                          </div>
                          {navigationParentMobileLinks.map((link) => {
                            return (
                              <MobileNavLink
                                key={link.id}
                                isActive={pathname === link.id}
                                onClick={() => {
                                  handleMobileLinkClick(link.id);
                                  setIsTogglerOpen(false);
                                }}
                              >
                                <span className="text-[#333333] font-medium font-avenir hover:bg-[#DFEEFF] w-full py-[10px] pl-[16px] flex text-[18px]">
                                  {link.label}
                                </span>
                              </MobileNavLink>
                            );
                          })}

                          <div>
                            <Button
                              variant="solid"
                              icon={<BiLogOut />}
                              onClick={(e) => handleLogout(e)}
                              className="py-[10px] px-[30px] my-[40px] ml-[16px] rounded-md bg-[#504CBC] border  text-[#ffffff] font-medium text-[18px]"
                            >
                              Logout
                            </Button>
                          </div>
                        </nav>
                      </div>
                    )}
                    {isTogglerOpen && (
                      <div
                        className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-30"
                        onClick={handleToggler}
                      ></div>
                    )}
                  </div>

                  <div className="hidden lg:flex lg:justify-end  lg:gap-x-4  lg:items-center  ">
                    {" "}
                    <div className="flex items-center gap-2">
                      <span className="text-[#333333] font-black text-base mr-4">
                        Hello,{" "}
                        {userData?.selected_profile?.contact_first_name
                          ? `${userData.selected_profile.contact_first_name}`
                          : "Parent"}
                      </span>
                    </div>
                    {/* Logout button */}
                    <button
                      className="text-black hover:bg-[#504CBC] text-[16px] hover:text-white rounded-full px-6 py-2 font-medium"
                      onClick={(e) => handleLogout(e)}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
        default:
          return (
            <>
              <div className="flex justify-between item-center font-avenir py-3 lg:py-5">
                {/* Left-aligned section*/}
                <div className="flex items-center gap-x-4">
                  <Link to="/">
                    <Logo />
                  </Link>
                  <div className="hidden lg:flex  ">
                    {/* Navigation buttons */}
                    <NavLink
                      className={({ isActive }) =>
                        `text-[#333333]  hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${
                          isActive ? "bg-[#504CBC] text-white" : ""
                        }`
                      }
                      to="/browse-daycares"
                    >
                      Browse Daycares
                    </NavLink>
                  </div>
                </div>

                {/* Right-aligned section */}
                <div className=" flex items-center">
                  <div className="lg:hidden">
                    <RxHamburgerMenu
                      color="#504CBC"
                      onClick={handleToggler}
                      size={20}
                    />

                    {isTogglerOpen && (
                      <div
                        ref={dropdownRef}
                        className="fixed top-0 right-0  w-full h-full bg-white z-40 shadow-lg overflow-scroll sm:w-1/2 "
                      >
                        <nav className="flex flex-col items-start ">
                          <div className="w-full border-b-[2px] border-[#504CBC] flex items-center  text-center pt-1 pb-2">
                            <div className="w-[10%]">
                              <FaArrowLeft
                                className="ml-[16px] text-[#504CBC]"
                                size={25}
                                onClick={() => {
                                  setIsTogglerOpen(false);
                                }}
                              />
                            </div>
                            <div className="font-avenir text-center font-bold text-[24px] text-[#504CBC] w-[90%]">
                              Menu
                            </div>
                          </div>

                          {navigationMobileLinks.map((link) => (
                            <MobileNavLink
                              key={link.id}
                              isActive={pathname === link.id}
                              onClick={() => {
                                handleMobileLinkClick(link.id);
                                setIsTogglerOpen(false);
                              }}
                            >
                              <span className="text-[18px] text-[#333333] font-medium font-avenir hover:bg-[#DFEEFF] w-full py-[10px] pl-[16px] flex ">
                                {link.label}
                              </span>
                            </MobileNavLink>
                          ))}
                          <div className="flex gap-2 mt-6 ml-4">
                            <div className="">
                              <Button
                                className="text-[18px] text-[#333333] font-medium font-avenir "
                                variant="solid"
                                onClick={() => handleLogin()}
                              >
                                Login
                              </Button>
                            </div>
                            <div>
                              <Button
                                className="text-[18px] text-[#333333] font-medium font-avenir bg-[#FF6131] "
                                variant="solid"
                                color={"#FF6131"}
                                onClick={handleSignUp}
                              >
                                Create Account
                              </Button>
                            </div>
                          </div>
                        </nav>
                      </div>
                    )}
                  </div>

                  <div className="hidden lg:flex lg:justify-end  lg:gap-x-4  lg:items-center  ">
                    {" "}
                    {/* Logout button */}
                    <NavLink
                      className={({ isActive }) =>
                        ` text-[#333333]  hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${
                          isActive ? "bg-[#504CBC] text-white" : ""
                        }`
                      }
                      to="/auth/sign-in"
                    >
                      {/* <button
                        className="text-black hover:bg-[#504CBC] text-[16px] hover:text-white rounded-full px-6 py-2 font-medium"
                        onClick={handleLogin}
                      > */}
                      Login
                      {/* </button> */}
                    </NavLink>
                    <button
                      className=" bg-[#FF6131] text-[16px] text-white rounded-full px-6 py-2 font-medium"
                      onClick={handleSignUp}
                    >
                      Create Account
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="">
        <div className="bg-white">
          <header className="container">{setRoleWiseHeader(userRole)}</header>
        </div>

        {!userData?.selected_profile?.submit_bank_account_details &&
          userData?.authority?.length > 0 &&
          userData?.authority[0] === "daycare" &&
          userData?.profile_id && (
            <>
              <div className="bg-[#FF6131] py-3">
                <div className="container ">
                  <div className="flex flex-col gap-2 md:flex-row md:justify-between md:items-center ">
                    <div>
                      <p className="text-white font-semibold text-base lg:text-lg">
                        Please add your bank details to Stripe to enable
                        autopay. Once set up, we’ll list your daycare on our
                        marketplace.
                      </p>
                    </div>
                    <div className="flex md:justify-end md:flex-base grow shrink-0 ">
                      <Button
                        className="uppercase text-sm lg:text-base 2xl:px-2 "
                        variant="solid"
                        color="#504CBC"
                        // loading={isStripeLoading ? true : false}
                        onClick={() => handleBankDetailsInStripe(false)} // Pass false for add
                      >
                        Add Details
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
      {isStripeAddModalVisible && (
        <div className="fixed top-0 left-0 w-full h-full  bg-black bg-opacity-50  flex items-center justify-center">
          <div className="container">
            <div className="w-full mx-auto bg-white rounded-[20px] overflow-hidden shadow-md relative sm:w-3/4 lg:w-1/2 2xl:w-1/3 ">
              <div className="bg-[#504CBC] text-white text-center font-bold font-avenirbold">
                <span className="text-[24px]">Add bank details</span>
              </div>
              <div className="text-center px-4 py-2 font-avenir lg:text-2xl">
                <span className="font-medium text-[24px] text-center">
                  Would you like to add your bank details to Stripe now to
                  enable autopay and list your daycare on our marketplace ?
                </span>
              </div>
              <div className="flex flex-col justify-center  items-center gap-4 px-4 py-6">
                <Link to={`${stripeAddDetailsUrl}`}>
                  <Button
                    className=""
                    variant="solid"
                    color="#00C1C1"
                    // loading={isDeleteLoading}
                    // onClick={(event) =>
                    //   handleConfirmToAddUpdateBankDetailsInStripe(event)
                    // }
                  >
                    Confirm
                  </Button>
                </Link>
                <Button
                  className=""
                  color={"#828282"}
                  onClick={(event) => handleCancel(event)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
