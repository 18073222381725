import React from "react";
import { Notification, toast } from "components/ui";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

const openNotification = (type, message) => {
  // console.log("type, message : ", type, message);
  const placement = window.innerWidth >= 640 ? "top-end" : "top-center";
  toast.push(
    <Notification
      type={type}
      duration={type === "success" ? 2000 : 3000}
      title={message}
      customIcon={
        type === "success" ? (
          <AiFillCheckCircle className="text-2xl " color="green" />
        ) : (
          <AiFillCloseCircle className="text-2xl" color="red" />
        )
      }
    />,
    { placement }
  );
};
export default openNotification;
